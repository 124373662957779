import { useLocalStorageValue } from '@react-hookz/web';
import { IHookReturn } from '@react-hookz/web/cjs/useStorageValue/useStorageValue';

interface GeekStorage {
  firstReferer: string | undefined;
  utmSource: string | undefined;
  utmMedium: string | undefined;
  utmCampaign: string | undefined;
  utmTerm: string | undefined;
  fromReferral: string | undefined;
  startUtcSession: string | undefined;
  deviceType: string | undefined;
  slugPosition: string | undefined;
}

export const useGeekStorage = (): IHookReturn<GeekStorage, null, null> =>
  useLocalStorageValue<GeekStorage>('geekStorage');
