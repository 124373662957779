import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

const env = process.env.NEXT_PUBLIC_CURRENT_ENV;
const validEnvs = ['production', 'staging'];

const shouldInitializeSentry = !!(env && validEnvs.includes(env));

/**
 * Applies configuration for Sentry.
 */
const configureSentry = (): void => {
  if (!shouldInitializeSentry) {
    return;
  }

  Sentry.init({
    dsn: 'https://c3917a2b62df46acb65c922a4c2d6726@o939291.ingest.sentry.io/5899428',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: env,
  });

  Sentry.configureScope((scope) => {
    scope.setTag('app', 'frontend');
  });
};

export default configureSentry;
