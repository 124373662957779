"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDate = exports.dateFormats = void 0;
const date_fns_1 = require("date-fns");
const locale_1 = require("date-fns/locale");
exports.dateFormats = {
    DAY_MONTH_YEAR: 'dd/MM/yyyy',
    DAY_MONTH_YEAR_HOUR_MINUTE: 'dd/MM/yyyy HH:mm',
    MONTH_LONG: 'MMMM',
    DAY_OF_WEEK_LONG: 'EEEE',
    HOUR_MINUTE: 'HH:mm',
    MONTH_DAY_YEAR: 'MM/dd/yyyy',
    LONG_MONTH_YEAR: 'MMMM yyyy',
    DAY_MONTH_YEAR_LONG: 'dd / MMMM / yyyy',
    MONTH_DAY_YEAR_LONG: 'MMMM dd, yyyy',
};
const localeOptions = {
    'pt-BR': locale_1.pt,
    en: locale_1.enUS,
};
const formatDate = (dateFormat, iso8601String, lang = 'pt-BR', fallback = '-') => {
    const dateTime = (0, date_fns_1.parseISO)(iso8601String ?? '');
    const isInvalidDate = Number.isNaN(dateTime.getTime());
    if (isInvalidDate) {
        return fallback;
    }
    const locale = localeOptions[lang];
    if (dateFormat === 'DAY_MONTH_YEAR_LONG') {
        const langSeparator = lang === 'pt-BR' ? 'de' : '';
        return (0, date_fns_1.format)(dateTime, exports.dateFormats[dateFormat], { locale }).replace(/\//g, langSeparator);
    }
    return (0, date_fns_1.format)(dateTime, exports.dateFormats[dateFormat], { locale });
};
exports.formatDate = formatDate;
