import { differenceInYears, parseISO } from 'date-fns';
import { Translate } from 'next-translate';

import { ImportLinkedinProfileQuery } from 'lib/generated/graphql';

type FormatDateAttributesReturnType = {
  monthEndDate: string;
  monthStartDate: string;
  yearEndDate: string;
  yearStartDate: string;
};

type FormatLinkedinDateAttributesEnterType = {
  startAt?: ImportLinkedinProfileQuery['importLinkedinProfile']['experiences'][0]['startsAt'];
  endsAt?: ImportLinkedinProfileQuery['importLinkedinProfile']['experiences'][0]['startsAt'];
};

/**
 * Calculates the number of minutes between two dates
 *
 * @param {Date} startDate, endDate
 * @returns {number}
 */
export const getMinutesBetweenDates = (
  startDate: Date,
  endDate: Date
): number => {
  const diff = endDate.getTime() - startDate.getTime();
  return diff / 60000;
};

/**
 * Split months and years for date get in database
 *
 * @param {Date} startDate
 * @param {Date} endDate
 *
 * @returns {FormatDateAttributesReturnType}
 */
export const formatDateAttributes = (
  startDate: string,
  endDate: string
): FormatDateAttributesReturnType => {
  const startDateFormat = startDate ? new Date(startDate) : null;
  const endDateFormat = endDate ? new Date(endDate) : null;

  let monthStartDate = '';
  let yearStartDate = '';

  if (startDateFormat) {
    const monthStartDateUnformated = startDateFormat.getMonth();
    monthStartDate = String(`0${monthStartDateUnformated + 1}`).slice(-2);
    yearStartDate = String(startDateFormat.getFullYear());
  }

  let monthEndDate = '';
  let yearEndDate = '';

  if (endDateFormat) {
    const monthEndDateUnformated = endDateFormat.getMonth();
    monthEndDate = String(`0${monthEndDateUnformated + 1}`).slice(-2);
    yearEndDate = String(endDateFormat.getFullYear());
  }

  return {
    monthEndDate,
    monthStartDate,
    yearEndDate,
    yearStartDate,
  };
};

/**
 * Split months and years for date get in linkedin
 *
 * @param {Date} startDate
 * @param {Date} endDate
 *
 * @returns {FormatDateAttributesReturnType}
 */
export const formatLinkedinDateAttributes = ({
  startAt,
  endsAt,
}: FormatLinkedinDateAttributesEnterType): FormatDateAttributesReturnType => {
  const startDateFormat = startAt
    ? new Date(startAt.year, startAt.month - 1)
    : null;

  const endDateFormat = endsAt ? new Date(endsAt.year, endsAt.month - 1) : null;

  let monthStartDate = '';
  let yearStartDate = '';

  if (startDateFormat) {
    const monthStartDateUnformated = startDateFormat.getMonth();
    monthStartDate = String(`0${monthStartDateUnformated + 1}`).slice(-2);
    yearStartDate = String(startDateFormat.getFullYear());
  }

  let monthEndDate = '';
  let yearEndDate = '';

  if (endDateFormat) {
    const monthEndDateUnformated = endDateFormat.getMonth();
    monthEndDate = String(`0${monthEndDateUnformated + 1}`).slice(-2);
    yearEndDate = String(endDateFormat.getFullYear());
  }

  return {
    monthEndDate,
    monthStartDate,
    yearEndDate,
    yearStartDate,
  };
};

/**
 * Create date string with month and year
 *
 * @param {Date} month
 * @param {Date} year
 *
 * @returns {FormatDateAttributesReturnType}
 */
export const formatStringsToDate = (month: string, year: string): string =>
  `${year}-${month}-01`;

/**
 * Returns the difference between two dates in years formatted in string
 *
 * @param {String} startDate
 * @param {String} endDate
 *
 * @returns {String}
 */
export const formatDifferenceInYears = (
  startDate: string,
  endDate: string | undefined,
  t: Translate
): string => {
  const years = differenceInYears(
    parseISO(endDate || new Date().toISOString()),
    parseISO(startDate)
  );
  if (years === 0) return t('years_less_than_1');

  return t('years', { count: years });
};
