import { Cookies } from 'react-cookie';
import {
  RequestHandler,
  InMemoryCache,
  ApolloClient,
  ApolloLink,
  HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { SentryLink } from 'apollo-link-sentry';
import * as Sentry from '@sentry/react';
import fetch from 'cross-fetch';

import { sanitizeSentryString } from 'lib/utils/sanitizeSentryData';
import { AUTH_TOKEN } from 'lib/constants/auth';
import * as paths from 'lib/constants/paths';

const monolithLink =
  process.env.NEXT_PUBLIC_MONOLITH_PATH || 'https://geekhunter.com.br';

const uri =
  process.env.NEXT_PUBLIC_GRAPHQL_URI || 'http://localhost:3000/graphql';

const httpLink = createUploadLink({
  uri,
  fetch,
  credentials: 'same-origin',
}) as unknown as ApolloLink | RequestHandler;

const ssrHttpLink = new HttpLink({
  uri,
  fetch,
  credentials: 'same-origin',
});

const authLink = setContext((_, { headers }) => {
  const userToken = new Cookies().get(AUTH_TOKEN);

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${userToken}`,
    },
  };
});

const authErrorLink = onError(({ networkError, operation, forward }) => {
  if (networkError && 'statusCode' in networkError) {
    switch (networkError.statusCode) {
      case 401:
        new Cookies().remove(AUTH_TOKEN, {
          path: '/',
        });

        window.location.href = `${monolithLink}/${paths.LOGOUT}`;
        break;
      default:
        break;
    }

    forward(operation);
  }
});

const sentryErrorLink = onError(
  ({ networkError, operation, response, forward }) => {
    if (!networkError && operation) {
      const errors = (response?.errors || [])
        .map((error) => error?.message)
        .map(sanitizeSentryString)
        .reduce((acc, curr, index) => ({ ...acc, [index]: curr }), {});

      const body = sanitizeSentryString(operation.query?.loc?.source?.body);

      Sentry.captureException(
        new Error(`GraphQL Error: ${operation.operationName}`),
        {
          contexts: {
            variables: operation.variables,
            response: {
              data: response?.data,
              errors,
            },
            query: {
              body,
            },
          },
        }
      );
    }

    if (networkError) {
      Sentry.captureException(new Error('GraphQL Error: Network error'), {
        contexts: {
          exception: {
            networkError,
          },
        },
      });
    }

    forward(operation);
  }
);

const sentryBreadcrumbLink = new SentryLink({
  uri,
});

const link = ApolloLink.from([
  authErrorLink,
  authLink,
  sentryBreadcrumbLink,
  sentryErrorLink,
  httpLink,
]);

const ssrLink = ApolloLink.from([
  sentryBreadcrumbLink,
  sentryErrorLink,
  ssrHttpLink,
]);

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  cache,
  link,
});

export const apolloClientSsr = new ApolloClient({
  cache,
  link: ssrLink,
  ssrMode: true,
});

export default apolloClient;
