import { ReactElement, useEffect } from 'react';

import { useGeekStorage } from 'lib/hooks/useGeekStorage';
import { getMinutesBetweenDates } from 'lib/utils/dateTimeUtils';
import { getDeviceType } from 'lib/utils/getDeviceType';
import { lastRenderedSlug } from 'lib/utils/lastRenderedSlug';
import { getUtmMedium, getUtmSource } from 'lib/utils/marketingParamsUtils';

export const CaptureMarketingParams = (): ReactElement => {
  const currentUTCTimeString = new Date().toISOString();
  const [geekStorage, setGeekStorage] = useGeekStorage();

  useEffect(() => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const candidateSlug = lastRenderedSlug();
    const slugPosition =
      candidateSlug !== '' ? candidateSlug : geekStorage?.slugPosition;
    const utmSource = getUtmSource(
      urlParams.get('utm_source') || '',
      document.referrer
    );
    const utmMedium = getUtmMedium(
      urlParams.get('utm_medium') || '',
      document.referrer
    );
    const utmCampaign = urlParams.get('utm_campaign') || '';
    const utmTerm = urlParams.get('utm_term') || '';
    const fromReferral = urlParams.get('from_referral') || '';
    const firstReferer = geekStorage?.firstReferer || document.referrer;

    if (geekStorage === null) {
      // Save the current referrer with utm's if available
      setGeekStorage({
        firstReferer,
        utmSource,
        utmMedium,
        utmCampaign,
        utmTerm,
        fromReferral,
        startUtcSession: currentUTCTimeString,
        deviceType: getDeviceType(),
        slugPosition,
      });
    } else {
      // if doesn't exist the prop firstReferrer set it
      // to the geekStorage object
      if (!geekStorage.firstReferer) {
        geekStorage.firstReferer = document.referrer;
      }
      // Only rewrite the localStorage if the session is older
      // than 30 min (default session time of web)
      const sessionMinutesLimit = 30.0;
      const shouldUpdateUTMs =
        !geekStorage.startUtcSession ||
        getMinutesBetweenDates(
          new Date(geekStorage.startUtcSession),
          new Date()
        ) > sessionMinutesLimit;

      if (shouldUpdateUTMs) {
        // for all the parameters we replace the values everytime
        // the users access the platform
        geekStorage.utmSource = utmSource;
        geekStorage.utmMedium = utmMedium;
        geekStorage.utmCampaign = utmCampaign;
        geekStorage.utmTerm = utmTerm;
        geekStorage.fromReferral = fromReferral;
        geekStorage.startUtcSession = currentUTCTimeString;
        geekStorage.deviceType = getDeviceType();
      }

      geekStorage.slugPosition = slugPosition;

      // The first referrer could be updated so we re-set the local storage
      setGeekStorage(geekStorage);
    }
  }, [currentUTCTimeString, geekStorage, setGeekStorage]);

  return <></>;
};
