import setLanguage from 'next-translate/setLanguage';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

export const useLocale = () => {
  const { lang } = useTranslation();
  const [cookies] = useCookies();

  useEffect(() => {
    (async () => {
      if (cookies.NEXT_LOCALE && lang !== cookies.NEXT_LOCALE)
        await setLanguage(cookies.NEXT_LOCALE);
    })();
  });

  return {
    lang,
  };
};
