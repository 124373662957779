const formatModule = require('./dist/utils/formatInterpolation');
const NAMESPACES =
  require('./dist/constants/translateNamespaces.js').TRANSLATE_NAMESPACES;

module.exports = {
  locales: ['pt-BR', 'en'],
  defaultLocale: 'pt-BR',
  extensionsRgx: /\.page\.(tsx|ts|js|jsx)$/,
  loadLocaleFrom: (lang, ns) =>
    import(`./src/locales/${lang}/${ns}.yaml`).then((m) => m.default),
  pages: {
    '/criar-perfil-gratis': [NAMESPACES.createProfile],
    '/companies/candidatos': [NAMESPACES.companyCandidates, NAMESPACES.global],
    '/companies/candidatos/[id]': [NAMESPACES.companyCandidates, NAMESPACES.global],
    '/companies/_candidatos': [NAMESPACES.companyCandidates, NAMESPACES.global],
    '/companies/signup/dashboard': [NAMESPACES.companiesSignupFlow],
    '/companies/accept-terms-of-use': [NAMESPACES.companyAcceptTermsOfUse],
    '/companies/paused-package': [NAMESPACES.companyPausedPackage],
    '/companies/security-settings': [
      NAMESPACES.companySecuritySettings,
      NAMESPACES.global,
    ],
    '/companies/company-users/list': [NAMESPACES.global, NAMESPACES.companyCandidates],
    '/companies/company-users/new': [NAMESPACES.global, NAMESPACES.companyCandidates],
    '/candidates/dashboard': [NAMESPACES.candidateDashboard],
    '*': [NAMESPACES.menuHeader, NAMESPACES.plataformFooter, NAMESPACES.global],
    '/companies/subscription': [NAMESPACES.global, NAMESPACES.companySubscription],
    '/companies/hub/salaries': [NAMESPACES.global, NAMESPACES.companyHubSalaries],
    '/companies/hub': [NAMESPACES.global, NAMESPACES.companyHub],
  },
  // function formatInterpolation will be called whenever an interpolation happens
  interpolation: {
    format: formatModule.formatInterpolation,
  },
};
