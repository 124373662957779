/**
 * Returns the device type based on the screen width
 *
 * @returns {string}
 */

export const getDeviceType = (): string => {
  const maxMobileWidth = 480;
  const maxTabletWidth = 1024;
  const screenWidth = window.screen.width;

  if (screenWidth < maxMobileWidth) {
    return 'mobile';
  }
  if (screenWidth < maxTabletWidth) {
    return 'tablet';
  }

  return 'desktop';
};
