"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDuration = void 0;
const translations = {
    'pt-BR': {
        lessThanAMonth: '1 mês',
        lessThanAYear: (months) => `${months} meses`,
        oneYear: '1 ano',
        oneYearAndOneMonth: '1 ano e 1 mês',
        oneYearAndMonths: (monthsLeft) => `1 ano e ${monthsLeft} meses`,
        years: (years) => `${years} anos`,
        yearsAndOneMonth: (years) => `${years} anos e 1 mês`,
        yearsAndMonths: (years, monthsLeft) => `${years} anos e ${monthsLeft} meses`,
    },
    en: {
        lessThanAMonth: '1 month',
        lessThanAYear: (months) => `${months} months`,
        oneYear: '1 year',
        oneYearAndOneMonth: '1 year and 1 month',
        oneYearAndMonths: (monthsLeft) => `1 year and ${monthsLeft} months`,
        years: (years) => `${years} years`,
        yearsAndOneMonth: (years) => `${years} years and 1 month`,
        yearsAndMonths: (years, monthsLeft) => `${years} years and ${monthsLeft} months`,
    },
};
const formatDuration = (duration, lang = 'pt-BR') => {
    let msg = '';
    if (duration === 1) {
        msg = translations[lang].lessThanAMonth;
    }
    else if (duration < 12) {
        msg = translations[lang].lessThanAYear(duration);
    }
    else if (duration < 24) {
        const monthsLeft = duration % 12;
        if (monthsLeft === 0) {
            msg = translations[lang].oneYear;
        }
        else if (monthsLeft === 1) {
            msg = translations[lang].oneYearAndOneMonth;
        }
        else {
            msg = translations[lang].oneYearAndMonths(monthsLeft);
        }
    }
    else {
        const years = Math.floor(duration / 12);
        const monthsLeft = duration % 12;
        if (monthsLeft === 0) {
            msg = translations[lang].years(years);
        }
        else if (monthsLeft === 1) {
            msg = translations[lang].yearsAndOneMonth(years);
        }
        else {
            msg = translations[lang].yearsAndMonths(years, monthsLeft);
        }
    }
    return msg;
};
exports.formatDuration = formatDuration;
