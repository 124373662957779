import replace from 'lodash/replace';
import * as R from 'remeda';

const keywordsToReplace = [/authenticationtoken/gi, /password/gi, /token/gi];

/**
 * Sanitizes an error string to be sent to Sentry, removing sensitive information.
 *
 * @param error The error string to sanitize.
 * @returns The sanitized error string.
 *
 * @example sanitizeSentryString('user and password') => 'user and <redacted>';
 */
export const sanitizeSentryString = (error: string | undefined): string =>
  R.reduce(
    keywordsToReplace,
    (acc, keyword) => replace(acc, keyword, '<redacted>'),
    error || '<empty>'
  );
