const utmMap = [
  {
    match: 'google',
    value: 'google',
  },
  {
    match: 'blog.geekhunter.com.br',
    value: 'blog',
  },
  {
    match: 'rhtech.geekhunter.com.br',
    value: 'blogrh',
  },
  {
    match: 'geekhunter.com.br',
    value: 'direct',
  },
  {
    match: 'linkedin',
    value: 'linkedin',
  },
  {
    match: 'lnkd.in',
    value: 'linkedin',
  },
  {
    match: 'linkin.bio',
    value: 'social',
  },
  {
    match: 'indeed',
    value: 'indeed',
  },
  {
    match: 'glassdoor',
    value: 'glassdoor',
  },
  {
    match: 'youtube',
    value: 'youtube',
  },
];

export const getUtmSource = (
  utmSource: string,
  firstReferrer?: string
): string => {
  if (utmSource) return utmSource;

  if (!firstReferrer) return 'direct';

  const mappedValue = utmMap.find(
    (item) => firstReferrer.indexOf(item.match) >= 0
  );

  return (mappedValue && mappedValue.value) || 'referral';
};

export const getUtmMedium = (
  utmMedium: string,
  firstReferrer?: string
): string => {
  if (utmMedium) return utmMedium;
  if (!firstReferrer || firstReferrer.indexOf('geekhunter.com.br') >= 0)
    return '(none)';
  if (firstReferrer.indexOf('google') >= 0) return 'organic';
  if (firstReferrer.indexOf('linkedin') >= 0) return 'social';
  return '';
};
