
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { ReactElement } from 'react';
import { ApolloProvider } from '@apollo/client';
import { AppProps } from 'next/app';
import { devTools } from '@ngneat/elf-devtools';
import { DefaultSeo } from 'next-seo';
import { NextQueryParamProvider } from 'next-query-params';
import { CookiesProvider } from 'react-cookie';
import { GoogleTagManager } from '@next/third-parties/google';

import { CaptureMarketingParams } from 'components/atoms/captureMarketingParams';
import { isProduction } from 'lib/constants/production';
import apolloClient from 'lib/settings/apolloClient';
import configureSentry from 'lib/settings/sentry';
import 'react-phone-input-2/lib/style.css';
import { LocaleProvider } from 'components/atoms/localeProvider';

import 'lib/settings/theme/global.css';

configureSentry();

// isProduction means the env is either PROD or STAGING
if (!isProduction && typeof window !== 'undefined') {
  devTools();
}

const metaTags = [
  {
    name: 'viewport',
    content:
      'width=device-width, initial-scale=1, maximum-scale=5, shrink-to-fit=no',
  },
  {
    name: 'Content-Security-Policy',
    content: 'upgrade-insecure-requests',
  },
];

const App = ({ Component, pageProps }: AppProps): ReactElement => (
  <>
    <DefaultSeo additionalMetaTags={metaTags} />
    <CaptureMarketingParams />
    <NextQueryParamProvider>
      <ApolloProvider client={apolloClient}>
        <CookiesProvider>
          <LocaleProvider>
            <Component {...pageProps} />
          </LocaleProvider>
        </CookiesProvider>
      </ApolloProvider>
    </NextQueryParamProvider>
    {process.env.NEXT_PUBLIC_CURRENT_ENV === 'production' && (
      <GoogleTagManager gtmId='GTM-PTK3FK' />
    )}
    {process.env.NEXT_PUBLIC_CURRENT_ENV === 'staging' && (
      <GoogleTagManager
        gtmId='GTM-PTK3FK'
        auth='K-i7wMAwVbmKkKX53Jp8yQ'
        preview='env-322'
      />
    )}
  </>
);

const __Page_Next_Translate__ = App;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  