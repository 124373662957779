"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatInterpolation = void 0;
const formatDate_1 = require("../formatDate");
const formatDuration_1 = require("../formatDuration");
const dateFormatters = {
    'pt-BR': (value) => (0, formatDate_1.formatDate)('DAY_MONTH_YEAR', value),
    en: (value) => (0, formatDate_1.formatDate)('MONTH_DAY_YEAR', value, 'en'),
};
const longDateFormatters = {
    'pt-BR': (value) => (0, formatDate_1.formatDate)('DAY_MONTH_YEAR_LONG', value),
    en: (value) => (0, formatDate_1.formatDate)('MONTH_DAY_YEAR_LONG', value, 'en'),
};
const longMonthAndYearFormatters = {
    'pt-BR': (value) => (0, formatDate_1.formatDate)('LONG_MONTH_YEAR', value),
    en: (value) => (0, formatDate_1.formatDate)('LONG_MONTH_YEAR', value, 'en'),
};
const durationTextFormatters = {
    'pt-BR': (value) => (0, formatDuration_1.formatDuration)(value),
    en: (value) => (0, formatDuration_1.formatDuration)(value, 'en'),
};
function formatInterpolation(value, format, lang) {
    if (format === 'shortDate' && typeof value === 'string')
        return dateFormatters[lang](value);
    if (format === 'longDate' && typeof value === 'string')
        return longDateFormatters[lang](value);
    if (format === 'longMonthAndYear' && typeof value === 'string')
        return longMonthAndYearFormatters[lang](value);
    if (format === 'durationText' && typeof value === 'number') {
        return durationTextFormatters[lang](value);
    }
    return value;
}
exports.formatInterpolation = formatInterpolation;
