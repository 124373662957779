import I18nProvider from 'next-translate/I18nProvider';

import { PropsWithChildren } from 'lib/@types';
import { useLocale } from 'lib/hooks/useLocale';

export const LocaleProvider = ({ children }: PropsWithChildren) => {
  const { lang } = useLocale();

  return <I18nProvider lang={lang}>{children}</I18nProvider>;
};
